// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Car Parts",
    description: "Auto parts come in varying shapes, designs, and brands. We offer custom car parts that can be used in most modern cars. Most Chinese auto part makers are able to replicate some branded auto parts.",
    image: '/Assets/products/automotive1.jpg',
  },
  {
    title: "Bicycle Accessories",
    description: "Proper supplies are vital for riders. Cycling bags, gloves, helmets, locks, and glasses are some essentials. You can add some special designs to make them more appealing to consumers.",
    image: '/Assets/products/automotive2.jpg',
  },
  {
    title: "Cleaning Items",
    description: "There are kinds of cleaning items for the car interior and exterior. We help you wholesale kinds of items like brushes and electrical cleaners like handheld vacuum cleaners.",
    image: '/Assets/products/automotive3.jpg',
  },
  {
    title: "Security Devices",
    description: "Such products are not only related to the vehicle itself but also to the drivers and passengers. The relative products include anti-theft devices, alcohol breath testers, safety belts, etc.",
    image: '/Assets/products/automotive4.jpg',
  },
  {
    title: "Exterior Accessories",
    description: "Car stickers, sunshades, or wraps help improve cars’ exterior look and functions. Adding unique and cool patterns to these items would be welcome for consumers.",
    image: '/Assets/products/automotive5.jpg',
  },
  {
    title: "Interior Accessories",
    description: "These accessories are mainly for storage, decoration, and protection. Car owners like to personalize their vehicles from mats to covers to keychains.",
    image: '/Assets/products/automotive6.jpg',
  },
];

const Automotive = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Automotive Part & Accessories" 
      heroTitle="Explore Our Automotive Part & Accessories" 
      heroImage="/Assets/productbanners/automotive-supplies-banner.jpg"
    />
  );
};

export default Automotive;
