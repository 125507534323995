import React from 'react';
import './hero.css';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function Hero() {

    const heroImageStyle = {
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
                    url('/Assets/heroimg/hero1.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // You can adjust this as needed
        width: '100%',   // You can adjust this as needed
      };
    return (
        <div className="hero-section wf-section" style={heroImageStyle}>
            <div className="hero-overlay">
                <div className="hero-wrapper">
                    <div className="hero-container">
                        <div className="hero-content">
                            <h1 className="hero-h1">Welcome to <span style={{color:"#16795e"}}>Soursee</span></h1>
                            <p className="hero-p">Management - Development - <span style={{color:"#16795e"}}>Sourcing</span> - Agency</p>
                            <Link to='/contact' className="btn glass-btn w-button">Get In Touch</Link>
                        </div>
                    </div>
                </div>
                <div className="wrapper-scroll">
                    <NavHashLink to="#about-us" className="scroll-button-wrapper w-inline-block">
                        <img src="https://uploads-ssl.webflow.com/605076e40f922161f3826d42/60509a9fcf237e13330874bd_001-down.svg" loading="lazy" alt="Scroll Down" />
                    </NavHashLink>
                </div>
            </div>
        </div>
    );
}

export default Hero;
