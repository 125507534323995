// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Handbags",
    description: "We help customize handbag styles that are very popular on Pinterest. We also offer stock handbags 10%-35% cheaper than Alibaba. PU leather is the most popular material among consumers.",
    image: '/Assets/products/handbags1.jpg',
  },
  {
    title: "Backpacks",
    description: "Common backpacks are made of cotton, polyester, PU leather, oxford. The materials determine the cost, and durability. We provide private-label solutions to differentiate your product from competitors.",
    image: '/Assets/products/handbags2.jpg',
  },
  {
    title: "Toiletry Bags",
    description: "Typically, toiletry bags are made of waterproof materials. Nylon, leather, or faux leather are durable fabrics; while plastic is a good option to produce clear bags.",
    image: '/Assets/products/handbags3.jpg',
  },
  {
    title: "Travel Bags",
    description: "Our travel bags include insulated bags for picnics, storage bags for luggage, etc. Popular fabrics are polyester, nylon, rattan, oxford, and cloth.",
    image: '/Assets/products/handbags4.jpg',
  },
  {
    title: "Pouches",
    description: "Most pouches are made of cotton, polyester, mesh, or plastic. They are made for different purposes, like for shopping, storage, or protection. They’re low-cost and high-margin.",
    image: '/Assets/products/handbags5.jpg',
  },
  {
    title: "Special Bags",
    description: "A bag can be used for different purposes. And there are also some bags with specific functions. For example, we also offer first aid bags, bicycle bags, storage bags, etc.",
    image: '/Assets/products/handbags6.jpg',
  },
];

const Bags = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Bags & Cases" 
      heroTitle="Explore Our Bags & Cases Collection" 
      heroSubtitle="High-quality Bags & Cases for every need"
      heroImage="/Assets/productbanners/bagbanner.jpg"
    />
  );
};

export default Bags;
