// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Makeup",
    description: "We’ve sourced and customized different makeups like eyeshadow, lipstick, mask, nail glue, eyelashes, etc., according to clients’ needs.",
    image: '/Assets/products/makeup1.jpg',
  },
  {
    title: "Tools & Accessories",
    description: "There are many types of makeup tools for new or veteran importers. Cosmetic tools include brushes, mirrors, nail clippers, etc.",
    image: '/Assets/products/makeup2.jpg',
  },
  {
    title: "Personal Care",
    description: "Our personal care tools mainly refer to oral, lips, skin, and body care tools, such as facial cleansers, electric toothbrushes, soap, ear candles, etc.",
    image: '/Assets/products/makeup3.jpg',
  },
  {
    title: "Medical Supplies",
    description: "Due to the COVID-19 pandemic, medical supplies are still in high demand these years. Our supplies include detection devices, face masks, gloves, first aid kits, etc.",
    image: '/Assets/products/makeup4.jpg',
  },
  {
    title: "Hair Supplies & Wigs",
    description: "We offer products like wigs, hair bands, clips, electric hair curlers, combs, scissors, hair dryers, and more. Wigs are especially popular in the American, and African markets.",
    image: '/Assets/products/makeup5.jpg',
  },
  {
    title: "Bags & Cases",
    description: "We help wholesale cosmetics packaging, including jars, bottles, tubes, and bags in different materials like plastic, glass, fabrics, and acrylic. It’s a good category for private labels.",
    image: '/Assets/products/makeup6.jpg',
  },
];

const Beauty = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Beauty and Health" 
      heroTitle="Explore Our Beauty and Health Collection" 
      heroImage= "/Assets/productbanners/beautybanner.jpg"
    />
  );
};

export default Beauty;
