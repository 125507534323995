import React from "react";
import './serviceinfo.css'

function Serviceinfo() {
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title">How we source the product?</h2>
          <div className="separator"></div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-xs-12">
            <div className="servhol">
              <div className="serholimg">
                {/* <img src="https://soursee.com/wp-content/uploads/2021/11/1.png" alt="Product Development" /> */}
              </div>
              <div className="colmeents">
                <h3>Product Development</h3>
                <p>We dive deep into product design, prototyping, and refining to make sure your product hits all the right notes</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="servhol">
              <div className="serholimg">
                {/* <img src="https://soursee.com/wp-content/uploads/2021/11/2.png" alt="Production Analysis" /> */}
              </div>
              <div className="colmeents">
                <h3>Production Analysis</h3>
                <p>We look at all the details—materials, processes, and costs—to find the best manufacturing solutions that are both efficient and effective</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="servhol">
              <div className="serholimg">
                {/* <img src="https://soursee.com/wp-content/uploads/2021/11/3.png" alt="Factory Acquisitions" /> */}
              </div>
              <div className="colmeents">
                <h3>Factory Acquisitions</h3>
                <p>We carefully choose factories that meet your needs in terms of quality, capacity, and cost, so you get exactly what you’re looking for.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="servhol">
              <div className="serholimg">
                {/* <img src="https://soursee.com/wp-content/uploads/2021/11/4.png" alt="Customization Management" /> */}
              </div>
              <div className="colmeents">
                <h3>Customization Management</h3>
                <p>We manage the customization process to make sure there’s clear communication between you and the factory’s R&D team, ensuring your product turns out just right.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="servhol">
              <div className="serholimg">
                {/* <img src="https://soursee.com/wp-content/uploads/2021/11/5.png" alt="Inspections and Packing" /> */}
              </div>
              <div className="colmeents">
                <h3>Inspections and Packing</h3>
                <p>Quality is our priority. We conduct thorough inspections at every stage—pre-production, in-line, and final—to ensure your product meets our high standards. We also handle all the packing to ensure everything arrives safely</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="servhol">
              <div className="serholimg">
                {/* <img src="https://soursee.com/wp-content/uploads/2021/11/6a.png" alt="Highly Monitored Shipment" /> */}
              </div>
              <div className="colmeents">
                <h3>Highly Monitored Shipment​</h3>
                <p>We keep a close eye on your shipment from start to finish. With regular updates and tracking, you’ll always know where your order is and when it will arrive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Serviceinfo;
