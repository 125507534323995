// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Small Appliance",
    description: "Electronic appliances have higher profit margins than consumer products generally. Cool and unique designs are a good marketing strategy to appeal to consumers.",
    image: '/Assets/products/appliance1.jpg',
  },
  {
    title: "Speaker & Headphone",
    description: "Adding your logo, using unique patterns, or designing your own packaging are common ways of customization. Remember to meet the regulations like FCC, CE, ROHS.",
    image: '/Assets/products/appliance2.jpg',
  },
  {
    title: "Computer & Accessories",
    description: "The computer industry is mainly concentrated in China’s southern areas like Guangdong province. We help you find different suppliers of computers and accessories there.",
    image: '/Assets/products/appliance3.jpg',
  },
  {
    title: "Phone & Accessories",
    description: "Smartphones are essential for consumers in the world, which increases the demand for phone accessories. It can be a competitive and also profitable business.",
    image: '/Assets/products/appliance4.jpg',
  },
  {
    title: "GPS Device",
    description: "Major products are GPS trackers, device holders, etc. The devices require licenses like WPC and BIS.",
    image: '/Assets/products/appliance5.jpg',
  },
  {
    title: "Electronic Components",
    description: "The components mainly include conductive slip rings, semiconductor laser diode, capacitor, fuses tubes.",
    image: '/Assets/products/appliance6.jpg',
  },
];

const Electronics = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Electronics Collection" 
      heroTitle="Explore Our Electronics Collection" 
      heroSubtitle="High-quality Electronics for every need"
      heroImage="/Assets/productbanners/electronics-banner.jpg"
    />
  );
};

export default Electronics;
