// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Paper Packaging",
    description: "The price of our custom paper packaging will be lower than your local packaging factory’s. They’re made of ordinary paperboard. They can be designed in variable shapes and patterns inside & outside",
    image: '/Assets/products/packaging1.jpg',
  },
  {
    title: "Glass Packaging",
    description: "Many industries require glass packaging for their products, such as the beverage or food industry. We help you to customize different types of glass containers like bottles, jars, flasks, tins, etc.",
    image: '/Assets/products/packaging2.jpg',
  },
  {
    title: "Plastic Packaging",
    description: "Plastic packaging is used in a wide range of products. There are many forms of plastic packaging, which can be rigid or flexible. PET is a common plastic for packaging. It’s recyclable and BPA-free.",
    image: '/Assets/products/packaging3.jpg',
  },
  {
    title: "Metal Packaging",
    description: "Aluminum, tinplate, and stainless steel are the main materials for metal packaging. They’re good for preserving a food or cosmetic product. They’re usually made into cans, tubes, boxes, trays, etc.",
    image: '/Assets/products/packaging4.jpg',
  },
  {
    title: "Corrugated Boxes",
    description: "Corrugated cartons are made up of a few layers of material rather than just a single sheet like cardboard. Thus, They’re often stronger and more durable for packing, shipping, and storing industrial parts.",
    image: '/Assets/products/packaging5.jpg',
  },
  {
    title: "Exquisite Gift Box",
    description: "These gift boxes are made of expensive cardboard with multiple inserts inside and processed with more complicated printing techniques. It’s available for high-end products like wine, jewelry, etc.",
    image: '/Assets/products/packaging6.jpg',
  },
];

const Package = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Packaging" 
      heroTitle="Explore Our Packaging Collection" 
      heroImage="/Assets/productbanners/packaging-banner.jpg"
    />
  );
};

export default Package;
