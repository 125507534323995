import React, { useState, useEffect } from 'react';
import './header.css'; 
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import './headeripad.css';
function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
      <img className="logo" src="/Assets/logo/logo.png" alt="My Website Logo" />
        <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <nav className={`navBar ${isOpen ? 'nav-open' : ''}`}>
          {isOpen && (
            <button className="cancelButton" onClick={toggleMenu}>
              &times; {/* or use an icon here */}
            </button>
          )}
          <ul className="navList">
            <li className="navItems">
              <NavLink className="navLinks" to="/" activeClassName="active" onClick={() => setIsOpen(false)}>Home</NavLink>
            </li>
            <li className="navItems">
              <NavHashLink className="hashLink" to='/#about-us'  onClick={() => setIsOpen(false)}>About Us</NavHashLink>
            </li>
            <li className="navItems">
              <NavHashLink className="hashLink" to='/#brand'  onClick={() => setIsOpen(false)}>Brand</NavHashLink>
            </li>
            <li className="navItems">
              <NavLink className="navLinks" to='/services' activeClassName="active" onClick={() => setIsOpen(false)}>Services</NavLink>
            </li>
            <li className="navItems">
              <NavLink className="navLinks" to='/our-products' activeClassName="active" onClick={() => setIsOpen(false)}>Products</NavLink>
            </li>
            <li className="navItems">
              <NavLink className="navLinks" to='/contact' activeClassName="active" onClick={() => setIsOpen(false)}>Contact Us</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
