import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import "./carousel.css";

const TabbedCarousel = () => {
  const [activeTab, setActiveTab] = useState("Tab 2");

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 2,
        nav: false, // 1 item for mobile devices
      },
      600: {
        items: 2, // 2 items for tablets
      },
      1000: {
        items: 4, // 4 items for desktop
      },
    },
  };

  return (
    <div id="brands" className="section ws wf-section">
      <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title" id="brand">Some of our brands</h2>
          <div className="separator"></div>
        </div>

        <div
          data-duration-in="300"
          data-duration-out="100"
          data-current="Tab 1"
          data-easing="ease"
          className="slide0 w-tabs"
        >
          <div className="tabs-menu w-tab-menu" role="tablist">
            <button
              data-w-tab="Tab 2"
              className={`brand-tab w-inline-block w-tab-link ${activeTab === "Tab 2" ? "w--current" : ""}`}
              onClick={() => setActiveTab("Tab 2")}
              role="tab"
              aria-controls="w-tabs-1-data-w-pane-0"
              aria-selected={activeTab === "Tab 2"}
            >
              <div>Customers served brands</div>
            </button>
            <button
              data-w-tab="Tab 3"
              className={`brand-tab w-inline-block w-tab-link ${activeTab === "Tab 3" ? "w--current" : ""}`}
              onClick={() => setActiveTab("Tab 3")}
              role="tab"
              aria-controls="w-tabs-1-data-w-pane-1"
              aria-selected={activeTab === "Tab 3"}
            >
              <div>Our own brands</div>
            </button>
          </div>

          <div className="w-tab-content">
            <div
              data-w-tab="Tab 2"
              className={`w-tab-pane ${activeTab === "Tab 2" ? "w--tab-active" : ""}`}
              id="w-tabs-1-data-w-pane-0"
              role="tabpanel"
              aria-labelledby="w-tabs-1-data-w-tab-0"
            >
              <div className="brands-wrapper">
                <OwlCarousel className="brands-grid owl-carousel owl-loaded " {...options}>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand1.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand2.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand3.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
          
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand4.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand5.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand6.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand7.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand8.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand9.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand10.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/brand11.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  {/* Add more items here */}
                </OwlCarousel>
              </div>
            </div>
            <div
              data-w-tab="Tab 3"
              className={`w-tab-pane ${activeTab === "Tab 3" ? "w--tab-active" : ""}`}
              id="w-tabs-1-data-w-pane-1"
              role="tabpanel"
              aria-labelledby="w-tabs-1-data-w-tab-1"
            >
              <div className="brands-wrapper">
                <OwlCarousel className="brands-grid owl-carousel" {...options}>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/slider1.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/slider2.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/slider3.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/slider4.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/slider5.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/slider6.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/slider7.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#" className="w-inline-block">
                      <img
                        src="/Assets/images/slider8.png"
                        loading="lazy"
                        alt=""
                        className="brand-image"
                      />
                    </a>
                  </div>
                  {/* Add more items here */}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabbedCarousel;
