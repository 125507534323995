// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "General Supplies",
    description: "Consumable office supplies like paper, pens, tapes, ink, and more are still needed daily. Due to lower prices and the latest production technology, China would be a good place to import such supplies.",
    image: '/Assets/products/office-supplies1.jpg',
  },
  {
    title: "Office Appliances",
    description: "Office appliance get work done efficiently. We provide cameras, laser spirit level, projectors, laser engraving machines, and more that will offer you the latest and advanced technology.",
    image: '/Assets/products/office-supplies2.jpg',
  },
  {
    title: "Collaboration Tools",
    description: "We offer a wide range of products, including paper products, writing instruments, envelopes, labels, folders, binders, staplers, scissors, tape, and more.",
    image: '/Assets/products/office-supplies3.jpg',
  },
  {
    title: "Mail Supplies",
    description: "Mail supplies mean the packaging and accessories to pack and ship documents, such as stamps, envelopes, boxes, etc.",
    image: '/Assets/products/office-supplies4.jpg',
  },
  {
    title: "Storage",
    description: "To keep the office organized, desk organizers, bookends, shelves and more are needed in an office. The commonly-used materials are wood or metal.",
    image: '/Assets/products/office-supplies5.jpg',
  },
  {
    title: "Decor",
    description: "Office decorations can create a pleasant environment and promote productivity. Customized curtains, wall art, pictures, and plants are all trending in the office.",
    image: '/Assets/products/office-supplies.jpg',
  },
];

const Officesupplies = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Office Supplies & Appliance" 
      heroTitle="Explore Our Office Supplies & Appliance" 
      heroImage="/Assets/productbanners/office-supplies-banner.jpg"
    />
  );
};

export default Officesupplies;
