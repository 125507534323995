// src/components/ServicesSection.js
import React from 'react';
import "./servicebox.css";
const Servicesbox = () => {
  return (
    <div id="next" className="section wf-section servicesa">
      <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title">OUR SERVICES</h2>
          <div className="separator"></div>
        </div>
        <div className="row">
          <div className="content _33 slide200">
            <div className="service-wrapper">
              <div className="icon-wrapper">
                <img src="/Assets/svg/1.svg" loading="lazy" alt="Logistics Icon" />
              </div>
              <h3 className="service-title">Logistics</h3>
              <p className="p15">Many roads lead to BRI and EU. 90% of the deliveries are transported by sea. However, if a delivery has to be processed more quickly, it can also be delivered by rail, air freight or truck.</p>
            </div>
          </div>
          <div className="content _33 slide200">
            <div className="service-wrapper">
              <div className="icon-wrapper">
                <img src="/Assets/svg/2.svg" loading="lazy" alt="Data Management Icon" />
              </div>
              <h3 className="service-title">Data Management</h3>
              <p className="p15">The preparation of the product data is an important part of product management. The organization of this data is an important part of our administration. We can handle most data, be it text, images or videos.</p>
            </div>
          </div>
          <div className="content _33 slide200">
            <div className="service-wrapper">
              <div className="icon-wrapper">
                <img src="/Assets/svg/3.svg" loading="lazy" alt="After Sale Service Icon" />
              </div>
              <h3 className="service-title">After Sale Service</h3>
              <p className="p15">After the sale, support begins. We offer a complete after-sale service. This ranges from the provision and dispatch of all necessary spare parts to advice over the phone. In cooperation.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicesbox;
