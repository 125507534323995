import React from "react";
import './serviceshero.css'
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
function Servicesection() {
  return (
    <div
      className="hero-section wf-section"
      style={{ backgroundImage: "url(/Assets/heroimg/hero2.jpg)" }}
    >
      <div className="hero-overlay">
      <div className="hero-wrapper">
        <div className="hero-container">
          <div className="hero-content">
            <h1 className="hero-h1">Our Services</h1>
            <p className="hero-p">Management - Development - Sourcing - Agency</p>
            <Link to="/contact" className="btn glass-btn w-button">
              Get In Touch
            </Link>
          </div>
        </div>
      </div>
      <div className="wrapper-scroll">
        <NavHashLink to="#plans" className="scroll-button-wrapper w-inline-block">
          <img src="https://uploads-ssl.webflow.com/605076e40f922161f3826d42/60509a9fcf237e13330874bd_001-down.svg" loading="lazy" alt="Scroll Down" />
        </NavHashLink>
      </div>
      </div>
     </div>
  );
}

export default Servicesection;
