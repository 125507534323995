import React from "react";
import './servicecolumn.css';
import { NavHashLink } from "react-router-hash-link";
function Servicecolumn(){
    return(
        <section className="column-1 c-extra" id="plans">
            <div className="container">
                <div className="con">
                <div className="col-1">
                    <h2 className="service-h2" id="proplan">Our Services</h2>
                    <p className="service-p">We specialize in connecting you with the most suitable suppliers in China, helping you obtain competitive quotations, and offering comprehensive manufacturing solutions—all at no cost to you.</p>
                    
                    <p className="service-p">Once you’re satisfied with the pricing and samples, you can proceed with your order, and we’ll manage the entire process, from mass production and quality inspection to seamless door-to-door logistics.</p>
                    <p className="service-p">At Soursee, we stand by the quality of our products. In the event of any quality issues, we offer free replacements or compensation to ensure your satisfaction.
                    </p>
                    
                    <p className="service-p"><b>Service fee:</b> None. All costs are factored into the product's unit price.</p>
                    <NavHashLink to='/contact#form1'><button className="Btn">Quote Now</button></NavHashLink>
                </div>
                <div className="col2">
                    <img src="/Assets/images/service1.png" />
                </div>

                
                </div>  

                <div className="con1">
                <div className="col-1">
                    <h2 className="service-h2" id="basicplan">Working with Your Suppliers</h2>
                    <p className="service-p">If you opt to use your own suppliers in China, we can collaborate to ensure that your products are manufactured to your specifications.</p>
                
                <p className="service-p">We provide onsite inspections during both pre-production and post-production stages and closely monitor the packing process. After ensuring quality, we handle all door-to-door logistics.</p>
                
                <p className="service-p"><b>Service fee:</b> 5% of the product value, with a minimum charge of $100.</p>
                <NavHashLink to='/contact#form1'><button className="Btn">Quote Now</button></NavHashLink>
            </div>
            <div className="col3">
                <img src="/Assets/images/basic.png" />
            </div>

                
                </div>  

            </div>
        </section>
    )
}

export default Servicecolumn;