import React from "react";
import Productcategory from "../components/product/productcategories";
import Producthero from "../components/product/producthero";
import CTA2 from "../components/homecom/cta2";
import Servicecolumn from "../components/servicecom/servicecolumn1";
import Faq from "../components/extra com/faq";
import CallToAction from "../components/homecom/homecta";

function Ourproducts(){
    return(
        <>
        <Producthero />
        <Productcategory />
        <CTA2 />
        <Servicecolumn />
        <Faq />
        <CallToAction />
        </>
    )
}

export default Ourproducts;

