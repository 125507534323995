// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Classic Toys",
    description: "Classic toys always have great and stable demand every year. We gather the most complete toy categories in different materials like plush, wooden toys, puzzles, etc.",
    image: '/Assets/products/toy1.jpg',
  },
  {
    title: "Outdoor Toys",
    description: "Outdoor toys like flying balls, climbing rocks, bubble guns, and golf toys are trending in the US and European markets. We ensure safe and durable play equipment for clients.",
    image: '/Assets/products/toy2.jpg',
  },
  {
    title: "Games",
    description: "You can find kids’ and family board games, card games, simple or complicated electronic games, and more here.",
    image: '/Assets/products/toy3.jpg',
  },
  {
    title: "Party supplies",
    description: "Party supplies add fun to your parties. It’s a profitable business to sell them because party goods are always needed.",
    image: '/Assets/products/toy4.jpg',
  },
  {
    title: "Funny & Novelty",
    description: "Cool and funny stuff attracts people. We can develop funny and unique toys from 0 to make you stand out from your competitors.",
    image: '/Assets/products/toy5.jpg',
  },
  {
    title: "Educational Toys",
    description: "Different from general toys, educational toys are designed to stimulate children’s learning. They’re usually suitable for toddlers.",
    image: '/Assets/products/toy6.jpg',
  },
];

const Toy = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Toys & Games" 
      heroTitle="Explore Our Toys & Games Collection" 
      heroSubtitle="High-quality clothing for every need"
      heroImage="/Assets/productbanners/toybanner.jpg"
    />
  );
};

export default Toy;
