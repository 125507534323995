import React from "react";
import './contactcall.css'
import { Link } from "react-router-dom";

function Contactcall() {
  return (
    <section className="contactsol">
      <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title" style={{whiteSpace:'nowrap'}}>Contact With Us</h2>
          <div className="separator"></div>
        </div>
        <Link to='/contact' className="contactlink">
        <button
          className="btns contsis"
        >
          Let's Talk
        </button>
        </Link>
      </div>
    </section>
  );
}

export default Contactcall;
