import React, { useRef, useState, useMemo} from 'react';
import { useForm } from 'react-hook-form';
import '../qoute/qoutecom.css'; // Import the CSS file for styling
import emailjs from '@emailjs/browser';
import CountryDropdown from './countries';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebaseconfig';
// import countryList from 'react-select-country-list'
// import Select from 'react-select'
function AdvancedForm() {
    const form = useRef();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' or 'error'

    const { register, handleSubmit, formState: { errors }, reset, setValue: setValue} = useForm();
   
    // const [selectedCountry, setSelectedCountry] = useState(null);
    // const options = useMemo(() => countryList().getData().map(country => ({ label: country.label, value: country.value })), []);
    const sendEmail = async (data) => {
        try {
            // Send email
            await emailjs.sendForm('service_vc7qigi', 'template_8f45z3e', form.current, 'MQfIg_oMhXOsIBa6Q');
            console.log('Email sent successfully!');
            
            // Save to Firebase
            await saveToFirebase(data);
            console.log('Document written successfully!');
            
            setMessage('Form submitted successfully!');
            setMessageType('success');
        } catch (error) {
            console.error('Error submitting form: ', error);
            setMessage('Error submitting form. Please try again.');
            setMessageType('error');
        } finally {
            setIsSubmitted(true); // Hide the form and show the message
        }
    };
    const saveToFirebase = async (data) => {
        try {
            const docRef = await addDoc(collection(db, 'formSubmissions'), data);
            console.log('Document written with ID: ', docRef.id);
        } catch (e) {
            console.error('Error adding document: ', e);
        }
    };
//    // Handle country selection
//    const handleCountryChange = selectedOption => {
//     setSelectedCountry(selectedOption);
//     setValue('country', selectedOption ? selectedOption.value : '',{ shouldValidate: true });

// };


    return (
        <div className="form-container">
            <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title-2">Let's Talk</h2>
          <div className="separator"></div>
        </div>
        </div>
            {!isSubmitted ? (
                <form ref={form} onSubmit={handleSubmit(sendEmail)} id='form1' className="advanced-form">
                    <div className='form-row'>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            className='fullname'
                            type="text"
                            id="name"
                            {...register('from_name', { required: 'Name is required' })}
                        />
                        {errors.from_name && <p className="error">{errors.from_name.message}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="lastName">Last Name:</label>
                        <input
                            className='fullname'
                            type="text"
                            id="lastName"
                            {...register('last_name', { required: 'Last Name is required' })}
                        />
                        {errors.last_name && <p className="error">{errors.last_name.message}</p>}
                    </div>
                    </div>
                    
                    <div className='form-row'>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            {...register('email', { 
                                required: 'Email is required', 
                                pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email address' }
                            })}
                        />
                        {errors.email && <p className="error">{errors.email.message}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="phone">Phone Number:</label>
                        <input
                            type="tel"
                            id="phone"
                            {...register('phone', { 
                                required: 'Phone Number is required',
                                pattern: { value: /^[0-9]{10}$/, message: 'Invalid phone number' }
                            })}
                        />
                        {errors.phone && <p className="error">{errors.phone.message}</p>}
                    </div>
                    </div>
                    <CountryDropdown register={register} errors={errors} />

                    <div className="form-group">
                        <label htmlFor="businessStage">Stage of Your Business:</label>
                        <select id="businessStage" {...register('business_stage', { required: 'Business stage is required' })}>
                            <option value="">Select Stage</option>
                            <option value="startup">Startup</option>
                            <option value="growth">Growth</option>
                            <option value="established">Established</option>
                            {/* Add more options as needed */}
                        </select>
                        {errors.business_stage && <p className="error">{errors.business_stage.message}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            {...register('message')}
                        ></textarea>
                    </div>

                    <button type="submit">Submit</button>
                </form>
            ) : (
                <div className={`message ${messageType}`}>
                    {message}
                </div>
            )}
        </div>
    );
}

export default AdvancedForm;
