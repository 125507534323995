// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Apparel",
    description: "China produces almost 60% of clothes in the world. We print logos and patterns to customize your clothes even with a low MOQ. We also offer different kinds of clothing in stock at factory-direct prices.",
    image: '/Assets/products/apparel.jpg',
  },
  {
    title: "Clothes Accessories",
    description: "We help you wholesale and customize all kinds of clothing accessories, like gloves, hats, belts, scarves, watches, sunglasses, ties, pins, belts, etc. Tell us what you need if you can’t find them in the list.",
    image: '/Assets/products/apparel1.jpg',
  },
  {
    title: "Undergarments",
    description: "We’re experienced in producing and designing types of undergarments in diverse fabrics. No matter seamless fabric, daily wear, or sexy style, we help you wholesale or customize any you want.",
    image: '/Assets/products/undergarment.jpg',
  },
  {
    title: "Jewelry",
    description: "With 10+ years of experience in the jewelry business, we’ve got over 35 jewelry factories with long-term cooperation. We help create brand-new designs or improve existing styles as per your needs.",
    image: '/Assets/products/jewelry.jpg',
  },
  {
    title: "Socks",
    description: "We help you find several sock factories even with a low MOQ (only $500). You can get the latest styles like traditional knitted styles or custom 3D-printed ones from us.",
    image: '/Assets/products/sock.jpg',
  },
  {
    title: "Shoes",
    description: "Zhejiang is one of the shoe manufacturing centers in China. We’ve sourced a wide range of fashion shoes like casual shoes, kids’ shoes, boots, and shoe accessories.",
    image: '/Assets/products/shoe.jpg',
  },
];

const ApparelCategory = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Apparel Collection" 
      heroTitle="Explore Our Apparel Collection" 
      heroSubtitle="High-quality clothing for every need"
      heroImage="/Assets/productbanners/apparelbanner.jpg"
    />
  );
};

export default ApparelCategory;
