// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Gold Plated Jewelry",
    description: "Cheap base metals plated with 14k or 18k gold are trending these 2 years due to their low-cost and elegant appearance. The commonly-used base metals are stainless steel, silver, copper, and brass.",
    image: '/Assets/products/jewelry1.jpg',
  },
  {
    title: "Stainless Steel Jewelry",
    description: "Stainless steel jewelry is tarnish-resistant, durable, and unisex. In the current market, 304 and 316L stainless steel are the most commonly used.",
    image: '/Assets/products/jewelry2.jpg',
  },
  {
    title: "Copper Jewelry",
    description: "Compared to gold or silver jewelry, copper jewelry has a vintage style. Copper is soft and durable. And copper alloys like brass and bronze are also popular in the jewelry industry.",
    image: '/Assets/products/jewelry3.jpg',
  },
  {
    title: "Pearl Jewelry",
    description: "Such products are not only related to the vehicle itself but also to the drivers and passengers. The relative products include anti-theft devices, alcohol breath testers, safety belts, etc.",
    image: '/Assets/products/jewelry4.jpg',
  },
  {
    title: "Silver Jewelry",
    description: "Sterling silver jewelry in the market is usually made of 925 silver. It looks high-graded and is affordable for consumers.",
    image: '/Assets/products/jewelry5.jpg',
  },
  {
    title: "Gemstone Jewelry",
    description: "K-gold jewelry inlaid with various gemstones and zircon is suitable for many occasions. Different gem-setting crafts can make different jewelry styles for consumers.",
    image: '/Assets/products/jewelry6.jpg',
  },
];

const Jewelrysupplies = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Jewelry Collection" 
      heroTitle="Explore Our Jewelry Collection" 
      heroSubtitle="High-quality clothing for every need"
      heroImage="/Assets/productbanners/jewelry-banner.jpg"
    />
  );
};

export default Jewelrysupplies;
