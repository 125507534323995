import React from "react";
import { Link } from "react-router-dom";
import "./productcategories.css"
import {
    ClothesIcon,
    LockerIcon,
    HandBag02Icon,
    ShampooIcon,
    ToyTrainIcon,
    FootballIcon,
    ElectricHome01Icon,
    Plant02Icon,
    TelevisionTableIcon,
    IconDog,
    KidIcon,
    Wrench01Icon,
    OfficeIcon,
    CarParking01Icon,
    BabyGirlDressIcon,
    FactoryIcon,
    DeliverySecure02Icon,
    CamperIcon,
    NecklaceIcon,
    IdeaIcon,
} from "../icons/icon";

function Productcategory() {
    const categories = [
        { icon: <ClothesIcon />, title: "Apparel", link: "/our_products/apparel" },
        { icon: <LockerIcon />, title: "Furniture", link: "/our_products/furniture" },
        { icon: <HandBag02Icon />, title: "Bags & Cases", link: "/our_products/bags-cases" },
        { icon: <ShampooIcon />, title: "Beauty", link: "/our_products/beauty" },
        { icon: <ToyTrainIcon />, title: "Toys", link: "/our_products/toys" },
        { icon: <FootballIcon />, title: "Sports", link: "/our_products/sports" },
        { icon: <ElectricHome01Icon />, title: "Home Supplies", link: "/our_products/home-supplies" },
        { icon: <Plant02Icon />, title: "Garden & Tools", link: "/our_products/garden-tools" },
        { icon: <TelevisionTableIcon />, title: "Electronics", link: "/our_products/electronics" },
        { icon: <IconDog />, title: "Pet Supplies", link: "/our_products/pet-supplies" },
        { icon: <KidIcon />, title: "Kids", link: "/our_products/baby-supplies-sourcing" },
        { icon: <Wrench01Icon />, title: "Tools", link: "/our_products/tools" },
        { icon: <OfficeIcon />, title: "Office Supplies", link: "/our_products/office-supplies" },
        { icon: <CarParking01Icon />, title: "Automobile", link: "/our_products/automotive" },
        { icon: <BabyGirlDressIcon />, title: "Adult Products", link: "/our_products/adult-products" },
        { icon: <FactoryIcon />, title: "Industrial", link: "/our_products/industrial" },
        { icon: <DeliverySecure02Icon />, title: "Packaging", link: "/our_products/packaging" },
        { icon: <CamperIcon />, title: "Outdoor & Camping", link: "/our_products/outdoor-camping" },
        { icon: <NecklaceIcon />, title: "Jewelry", link: "/our_products/jewelry" },
        { icon: <IdeaIcon />, title: "Innovation", link: "/our_products/lighting-sourcing" },
    ];

    return (
        <>
        <section className="product-category-section">
        <div className="container">
            <div className="product-grid">
            <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title-2" id="p-category">Product Categories</h2>
          <div className="separator"></div>
        </div>
        </div>
                {categories.map((category, index) => (
                        <div className="product-box">
                        <Link to={category.link} className="product-link">
                            <div className="product-content">
                            <div className="p-icon">
                                {category.icon}
                            </div>
                            <h3>{category.title}</h3>
                            </div>
                            </Link>
                        </div>
                    
                ))}
            </div>
        </div>
    </section>
    </>
    
    );
}

export default Productcategory;
