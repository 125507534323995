// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Basic Tools",
    description: "There are hundreds of basic must-have tools such as tweezers, hammers, pliers, wrenches, etc. It’s profitable to sell them in sets.",
    image: '/Assets/products/tools1.jpg',
  },
  {
    title: "Electric/Air Tools",
    description: "This category covers drill brushes, electronic test pens, polishing brushes, cutting discs, drill bits, and foam pads.",
    image: '/Assets/products/tools2.jpg',
  },
  {
    title: "Hardware Accessories",
    description: "Hardware accessories are a broad category. General accessories we sell include fasteners, bearings, springs, seals, rigging, gears, molds, and abrasives.",
    image: '/Assets/products/tools3.jpg',
  },
  {
    title: "Lock & Keys",
    description: "Besides general locks and keys, we also customize accessories like keychains and no-touch door openers, which are usually made of stainless steel, zinc, silver, etc.",
    image: '/Assets/products/tools4.jpg',
  },
  {
    title: "Building Materials",
    description: "China is a good source of building materials, providing with all kinds of items—from tiles and wood flooring to fixtures and furniture, and so much more.",
    image: '/Assets/products/tools5.jpg',
  },
  {
    title: "Heating & Cooling",
    description: "Heated products made in China like electric blankets and heated scarves are trending especially in the European market. They are usually affordable and energy-saving.",
    image: '/Assets/products/tools6.jpg',
  },
];

const Hardware = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Hardware Collection" 
      heroTitle="Explore Our Hardware Collection" 
      heroImage="/Assets/productbanners/hardware-banner.jpg"
    />
  );
};

export default Hardware;
