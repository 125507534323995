// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Home Decor",
    description: "There is a wide variety of niches to choose from when it comes to selling home decor. Popular home decor categories are wall art, lamps, scented candles, etc.",
    image: '/Assets/products/home1.jpg',
  },
  {
    title: "Appliance",
    description: "The margins of home and kitchen appliances are higher than the standard products. Make sure to meet the regulations in different countries before importing.",
    image: '/Assets/products/home2.jpg',
  },
  {
    title: "Lighting",
    description: "We help source and customize household, outdoor, commercial, and smart lighting for you. Except for LED lighting, we also provide candles and accessories.",
    image: '/Assets/products/home3.jpg',
  },
  {
    title: "Sundries",
    description: "There are hundreds of sundry categories to select from. Find a niche market from these general products to narrow your competition and make profits.",
    image: '/Assets/products/home4.jpg',
  },
  {
    title: "Bath",
    description: "This category ranges from sanitary supplies to shower products and small appliances. It’s profitable to sell on private labels, like building your own brand or adjusting the items.",
    image: '/Assets/products/home5.jpg',
  },
  {
    title: "Bedding",
    description: "Our bedding selections include pillows, blankets, sleeping masks, bed sheets, etc. Common materials include fiber, cotton, plush, polyester, wool, and more.",
    image: '/Assets/products/home6.jpg',
  },
  {
    title: "Kitchen & Dining",
    description: "There is a wide selection of kitchen utensils. Popular materials include metal, silicone, wood, glass, and synthetic utensils. And make sure that utensils are all passed the required certificates.",
    image: '/Assets/products/home7.jpg',
  },
  {
    title: "Smoking Accessories",
    description: "Water pipes, Hookah are hot-selling especially in the Arab States, USA, and European markets. Customizing these tools in different shapes, colors, and patterns would be a good way to increase sales.",
    image: '/Assets/products/home8.jpg',
  },
];

const Homesupplies = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Home Supplies" 
      heroTitle="Explore Our Home Supplies Collection" 
      heroSubtitle="High-quality Home Supplies for every need"
      heroImage="/Assets/productbanners/homesuppliesbanner.jpg"
    />
  );
};

export default Homesupplies;
