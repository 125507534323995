// src/components/Faq/Faq.js
import React, { useState } from 'react';
import './Faq.css';
const faqs = [
  { question: 'What’s included in Soursee’s Pro Plan?', answer: 'The Pro Plan offers complete support for sourcing, manufacturing, quality control, and logistics, all at no extra charge. The costs are included in the unit price of the product.' },
  { question: 'How does the Basic Plan compare to the Pro Plan? ', answer: 'The Basic Plan provides essential sourcing and manufacturing services with no upfront fees. It’s perfect if you need straightforward solutions without extensive support.' },
  { question: 'How does Soursee ensure the quality of products?', answer: 'We perform detailed inspections at three stages—pre-production, in-line, and final—to make sure everything meets our high standards. If there are any quality issues, we offer free replacements or compensation.' },
  { question: 'Are there extra costs for Soursee’s services?', answer: 'No, there are no extra service fees. All costs are included in the unit price of the product, so you only pay for the product itself.' },
];

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq-section">
    <div className="faq-container">
    <div className="container">
        <div className="title-wrapper slide0">
          <h2 className="section-title-2">FAQ</h2>
          <div className="separator"></div>
        </div>
        </div>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => handleToggle(index)}>
            <h3>{faq.question}</h3>
            <span className={`faq-icon ${activeIndex === index ? 'open' : ''}`}>+</span>
          </div>
          <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
    </section>
  );
};

export default Faq;
