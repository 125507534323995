// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Indoor Lighting",
    description: "Lighting is one of the most important elements of your home’s design. Indoor lighting is made in different forms like ceiling lighting, wall sconces, pendants, etc. LED bulbs are the most commonly-used materials.",
    image: '/Assets/products/lights1.jpg',
  },
  {
    title: "Decorative Lights",
    description: "Besides brightening functions, decorative lights are usually designed in changeable colors and styles. Festival lights are also popular during the holiday, especially in the US and EU markets.",
    image: '/Assets/products/lights2.jpg',
  },
  {
    title: "Special Lighting",
    description: "Outdoor Lighting includes wall wash lights, lawn lights, garden lights, floodlights, underwater lights, street lights, etc. These lighting items should be waterproof and dustproof.",
    image: '/Assets/products/lights3.jpg',
  },
  {
    title: "Outdoor Lighting",
    description: "Outdoor Lighting includes wall wash lights, lawn lights, garden lights, floodlights, underwater lights, street lights, etc. These lighting items should be waterproof and dustproof.",
    image: '/Assets/products/lights4.jpg',
  },
  {
    title: "Candle",
    description: "Scented candles are niche products with low production costs and high profits. The most popular ones in the market are jar candles and special-shaped candles. Customizing special packaging is also a good strategy for marketing.",
    image: '/Assets/products/lights5.jpg',
  },
  {
    title: "Smart Lighting",
    description: "Smart lighting devices need to connect to the internet. They enable consumers to save energy and control the lighting remotely or by voice. They have higher requirements for materials so the production cost would be higher.",
    image: '/Assets/products/lights6.jpg',
  },
];

const Lighting = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Lights & Lighting" 
      heroTitle="Explore Our Light & Lighting Collection" 
      heroImage="/Assets/productbanners/lighting-banner.jpg"
    />
  );
};

export default Lighting;
