// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Fishing",
    description: "Fishing equipment is a niche product that serves fishing lovers. Generally, consumers will pay attention to the appearance and function of products.",
    image: '/Assets/products/outdoor-supplies1.jpg',
  },
  {
    title: "Cycling",
    description: "The vital supplies for cyclists are the bike itself, a helmet, proper shoes, and clothing. These products aim to improve comfort and performance.",
    image: '/Assets/products/outdoor-supplies.jpg',
  },
  {
    title: "Emergency Kits",
    description: "Emergency supplies are a must for outdoor enthusiasts. These emergency items are sold in a set to meet consumers’ every need. A kit usually consists of an emergency blanket, a compass, a knife, etc.",
    image: '/Assets/products/outdoor-supplies3.jpg',
  },
  {
    title: "Camping & Hiking",
    description: "From a sleeping pad to a headlamp to trekking poles, there is a wide assortment of camping products for adventurers. Especially in the USA, the camping market is rapidly increasing these years.",
    image: '/Assets/products/outdoor-supplies4.jpg',
  },
  {
    title: "Traveling",
    description: "Travel items make trips easier and more comfortable. Things like tents, storage bags, and pillows are all in high demand for travelers.",
    image: '/Assets/products/outdoor-supplies5.jpg',
  },
  {
    title: "Umbrella/ Sunshade",
    description: "We offer custom and tailor-made umbrellas and sun caps. There are golf umbrellas, transparent umbrellas, and folding umbrellas",
    image: '/Assets/products/outdoor-supplies6.jpg',
  },
];

const Outdoorsupplies = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Outdoor Supplies" 
      heroTitle="Explore Our Outdoor Supplies" 
      heroImage="/Assets/productbanners/outdoor-banner.jpg"
    />
  );
};

export default Outdoorsupplies;
