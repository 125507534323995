// src/components/CallToAction.js
import React from 'react';
import './CallToAction.css';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <div className="cta-container">
      <h2 className="cta-heading">Join Us Today!</h2>
      <p className="cta-text">Take the first step towards a brighter future. Sign up now to get started!</p>
      <Link to="/services"><button className="cta-button">Get Started</button></Link>
    </div>
  );
};

export default CallToAction;
