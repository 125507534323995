// src/pages/PrivacyPolicy.js
import React from 'react';
import '../components/privacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className='policy-sec'>
    <div className="privacy-policy-page">
      <h1>Privacy Policy</h1>
      <p>
        At Soursee, we’re committed to protecting your privacy and ensuring your personal information is handled with the utmost care. This Privacy Policy explains how we collect, use, and safeguard your information when you interact with our services. We want you to feel confident and informed about how your data is managed.
      </p>

      <section>
        <h2>1. Information We Collect</h2>
        <h3>Personal Information:</h3>
        <p>
          When you use our services, we may collect personal information such as your name, email address, phone number, and business details. This information helps us provide you with tailored services and respond to your needs effectively.
        </p>
        <h3>Usage Data:</h3>
        <p>
          We collect data on how you interact with our website and services, including IP addresses, browser types, and pages visited. This helps us understand how our services are used and improve your experience.
        </p>
        <h3>Transaction Information:</h3>
        <p>
          We collect details related to your transactions with us, such as order history and payment information. This information is essential for processing orders, managing logistics, and providing customer support.
        </p>
      </section>

      <section>
        <h2>2. How We Use Your Information</h2>
        <p>
          <strong>To Provide and Improve Our Services:</strong> We use your information to deliver the services you’ve requested, such as managing logistics, data management, and after-sale support. Your data helps us enhance our services and ensure a seamless experience.
        </p>
        <p>
          <strong>To Communicate with You:</strong> We may use your contact information to send you updates about your orders, respond to your inquiries, and provide information about our services. We aim to keep our communications relevant and helpful.
        </p>
        <p>
          <strong>To Ensure Security:</strong> Your data helps us monitor and protect against fraud and unauthorized access. We use advanced security measures to safeguard your information and ensure a secure environment.
        </p>
        <p>
          <strong>To Analyze and Improve:</strong> We use usage data to analyze trends and understand how our services are used. This helps us identify areas for improvement and enhance our offerings.
        </p>
      </section>

      <section>
        <h2>3. How We Share Your Information</h2>
        <p>
          <strong>With Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in delivering our services, such as logistics partners and payment processors. These parties are required to protect your data and use it only for the purposes specified by us.
        </p>
        <p>
          <strong>For Legal Compliance:</strong> We may disclose your information if required by law, to comply with legal obligations, or to protect our rights and the rights of others.
        </p>
        <p>
          <strong>With Your Consent:</strong> We will share your information with others only if you have provided explicit consent for us to do so.
        </p>
      </section>

      <section>
        <h2>4. Your Choices and Rights</h2>
        <p>
          <strong>Access and Update Your Information:</strong> You have the right to access and update your personal information. If you need to make changes or correct inaccuracies, please contact us, and we’ll assist you.
        </p>
        <p>
          <strong>Opt-Out of Communications:</strong> You can opt out of receiving marketing communications from us at any time. Follow the unsubscribe instructions in the emails or contact us directly to update your preferences.
        </p>
        <p>
          <strong>Request Data Deletion:</strong> If you wish to have your personal data deleted, please let us know. We’ll process your request in accordance with applicable laws and regulations.
        </p>
      </section>

      <section>
        <h2>5. Security of Your Information</h2>
        <p>
          We take the security of your data seriously and implement various measures to protect it from unauthorized access, loss, or misuse. However, no system is completely secure, so we encourage you to take precautions when sharing personal information online.
        </p>
      </section>

      <section>
        <h2>6. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on our website, and we encourage you to review this policy periodically to stay informed about how your information is protected.
        </p>
      </section>

      <section>
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions or concerns about our Privacy Policy or how we handle your information, please don’t hesitate to reach out. You can contact us at:
        </p>
        <p>
          <strong>Email:</strong> info@soursee.com<br />
          <strong>Phone:</strong> +86 188 1857 8873<br />
          <strong>Address:</strong> A201, Yuxing Building, Shajing Road, Bao'an District, Shenzhen - CHINA (518104)
        </p>
        <p>
          Thank you for trusting Soursee with your information. We’re committed to protecting your privacy and providing you with a great experience.
        </p>
      </section>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
