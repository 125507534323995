import React from "react";
import { NavHashLink } from "react-router-hash-link";

function Qoutehero(){
    return(
        <div
        className="hero-section wf-section"
        style={{ backgroundImage: "url(/Assets/heroimg/contact.png)" }}
      >
        <div className="hero-overlay">
        <div className="hero-wrapper">
          <div className="hero-container">
            <div className="hero-content">
              <h1 className="hero-h1">Get A Quote</h1>
              <p className="hero-p">Management - Development - Sourcing - Agency</p>
              <NavHashLink to="#form1" className="btn glass-btn w-button">
                Get In Touch
              </NavHashLink>
            </div>
          </div>
        </div>
        <div className="wrapper-scroll">
          <NavHashLink to="#project" className="scroll-button-wrapper w-inline-block">
            <img src="https://uploads-ssl.webflow.com/605076e40f922161f3826d42/60509a9fcf237e13330874bd_001-down.svg" loading="lazy" alt="Scroll Down" />
          </NavHashLink>
        </div>
        </div>
       </div>
    )
}

export default Qoutehero;