import React from "react";
import Hero from "../components/homecom/hero";
import Tabmenu from "../components/homecom/tabs-menu";
import TabbedCarousel from "../components/homecom/carousel";
import Serviceinfo from "../components/homecom/serviceinfo";
import Contactcall from "../components/homecom/contactcall";
import Hometwosec from "../components/homecom/hometwosec";
import CallToAction from "../components/homecom/homecta";
import Testimonial from "../components/homecom/testimonial";
import CTA2 from "../components/homecom/cta2";
import Faq from "../components/extra com/faq";

function Homepage(){
    return(
        <div>
           <Hero />
          <Tabmenu />
          <Hometwosec />
          <CallToAction />
          <TabbedCarousel />
          <Testimonial />
          <Faq />
          <Serviceinfo />
          <CTA2 />
          <Contactcall />
        </div>
    );
};

export default Homepage;