// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Pet Toys",
    description: "Toys for pets are always in high demand. All types of our pet toys are produced in hypoallergenic materials, safe for pets’ health",
    image: '/Assets/products/pet1.jpg',
  },
  {
    title: "Grooming Tools",
    description: "Gloves, shower heads, toothbrushes, combs, paw cleaners, and more are common supplies for pet grooming.",
    image: '/Assets/products/pet2.jpg',
  },
  {
    title: "Pet Accessories",
    description: "We’ve sourced and customized types of pet beds, carriers, wearing, feeding bowls, etc. Our products are all certified based on different countries’ policies.",
    image: '/Assets/products/pet3.jpg',
  },
  {
    title: "Collar & Harness",
    description: "Collars and harnesses are must-have parts for pets to go out. The main materials in production are leather, nylon, canvas, and other water-repellent fabrics.",
    image: '/Assets/products/pet4.jpg',
  },
  {
    title: "Pet Beds",
    description: "Choosing comfortable and durable pet bed materials is a crucial consideration for pet owners. The commonly-used materials include faux fur, polyester, cotton, plush, leather, etc.",
    image: '/Assets/products/pet5.jpg',
  },
  {
    title: "Pet Carriers",
    description: "Pet owners prefer to take their dogs or cats out of the house. In that case, a safe pet transportation solution is vital. Products like comfy car seats for dogs, and cat backpacks are all in high demand.",
    image: '/Assets/products/pet6.jpg',
  },
];

const Pet = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Pet Supplies" 
      heroTitle="Explore Our Pet Supplies" 
      heroImage="/Assets/productbanners/pet-banner.jpg"
    />
  );
};

export default Pet;
