// ProductGridLayout.js
import React from 'react';
import './productgrid.css';
import AdvancedForm from '../qoute/qoutecom';
import { NavHashLink } from 'react-router-hash-link';
const ProductGridLayout = ({ products, title, heroTitle, heroSubtitle, heroImage }) => {
  return (
    <>
      {/* Hero Section */}
      <div
        className="banner-section"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="hero-wrapper">
          <div className="hero-container">
            <div className="hero-content">
              <h1 className="banner-h1">{heroTitle}</h1>
              <p className="hero-p">{heroSubtitle}</p>
            </div>
          </div>
        </div>
        {/* <div className="wrapper-scroll">
          <a href="#about" className="scroll-button-wrapper w-inline-block">
            <img src="https://uploads-ssl.webflow.com/605076e40f922161f3826d42/60509a9fcf237e13330874bd_001-down.svg" loading="lazy" alt="Scroll Down" />
          </a>
        </div> */}
      </div>

      {/* Product Grid Section */}
      <section className='p-section'>
        <div className='p-container'>
        <div className="container">
          <h2>{title}</h2>
          <div className="p-grid">
            {products.map((product, index) => (
              <div className="p-box" key={index}>
                <div className="p-img"><img src={product.image} alt={product.title} /></div>
                <div className="p-con">
                  <h3>{product.title}</h3>
                  <p>{product.description}</p>
                  <NavHashLink to='#form1'><button className="qoute-btn">Get A Quote</button></NavHashLink>
                </div>
              </div>
            ))}
          </div>
          </div>
        </div>
      </section>
      <section>
      <div className='form2'>
        <AdvancedForm />
      </div>
      </section>


    </>
  );
};

export default ProductGridLayout;
