import React from "react";
import './qoutebox.css';
import { NavHashLink } from 'react-router-hash-link';

function Qoutebox(){
    return(
        <section className="sec">
            
            <div className="container">
            <div className="title-wrapper slide0">
          <h2 className="section-title" id="project">Start You Project</h2>
          <div className="separator"></div>
        </div>
                <div className="qoutebox">
                    <div className="qoute-box1">
                        <h2 className="qoute-h2">Find New <span style={{color:"#16796e", fontWeight: "700"}}>Suppliers</span></h2>
                        <p>If you are looking for new suppliers, we can help you find the best match and offer you a competitive price along with a cost-effective manufacturing solution. Compare our offer with any of your existing suppliers to see the value we can provide.</p>
                        <p>Check out our <NavHashLink to='/services/#proplan'>Pro Service Plan</NavHashLink> for more details.</p>
                        <NavHashLink to='/contact#form1'><button className="border-btn">i need this plan</button></NavHashLink>
                    </div>
                    <div className="qoute-box1">
                        <h2 className="qoute-h2">Manage My <span style={{color:"#16796e", fontWeight: "700"}}>Suppliers</span></h2>
                        <p>If you prefer to use your own suppliers, we can collaborate with them and manage the entire purchasing process from production to quality inspection and door-to-door logistics. Our support will significantly enhance your sourcing efficiency.</p>
                        <p>Discover more details about our  <NavHashLink to='/services/#basicplan'>Basic Service Plan</NavHashLink>.</p>
                        <NavHashLink to='/contact#form1'><button className="border-btn">i need this plan</button></NavHashLink>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Qoutebox;

