// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBthhQm9pn4Ao42ca8qFh6IY-VfbdCt1iE",
    authDomain: "soursee-backend.firebaseapp.com",
    projectId: "soursee-backend",
    storageBucket: "soursee-backend.appspot.com",
    messagingSenderId: "569648402617",
    appId: "1:569648402617:web:f84d375a21f8db8d6e0b68",
    measurementId: "G-01GJ619Z21"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db };
