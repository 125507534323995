import React from "react";
import './footer.css';
import { Link } from "react-router-dom";
const Footer = () => {
    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="copyright">
            © {new Date().getFullYear()} Copyright | All Right Reserved
          </div>
          <div className="privacy-policy">
            <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
            <Link to="/disclaimer" className="footer-link">Disclaimer</Link>
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;