// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Adult Toys",
    description: "Hot-selling sex toys are sucking eggs, massage vibrators, exercise balls, etc. You can customize them in different shapes and colors.",
    image: '/Assets/products/adult-product1.jpg',
  },
  {
    title: "Sexy Apparel",
    description: "The common types of costumes are night dresses and lingerie. Usually, the popular styles are made of silk, nylon mesh, and sheer fabrics.",
    image: '/Assets/products/adult-product2.jpg',
  },
  {
    title: "Bondage Gear",
    description: "Popular bondage gears include restraints, chastity devices, gags and muzzles, etc.",
    image: '/Assets/products/adult-product3.jpg',
  },
  {
    title: "Sensual Delights",
    description: "Such products are not only related to the vehicle itself but also to the drivers and passengers. The relative products include anti-theft devices, alcohol breath testers, safety belts, etc.",
    image: '/Assets/products/adult-product4.jpg',
  },
  {
    title: "Novelties",
    description: "Some items made in shapes of sex elements are trending. For example, a vase of a female body looks very elegant and attractive to consumers.",
    image: '/Assets/products/adult-product5.jpg',
  },
  {
    title: "Sex Accessories",
    description: "The accessories include ramps, cushions, swings, and bedding. Such items add more fun for users and are popular in the market.",
    image: '/Assets/products/adult-product6.jpg',
  },
];

const Adultproducts = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Adult Products" 
      heroTitle="Explore Our Adult Products" 
      heroImage="/Assets/productbanners/adult-product-banner.jpg"
    />
  );
};

export default Adultproducts;
