// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Outdoor Furniture",
    description: "We had a wide selection of outdoor options, from garden sets to outdoor dining sets, and pool furniture. We offer different materials for outdoor furniture including wooden, metal, plastic, stainless steel, etc.",
    image: '/Assets/products/furniture1.jpg',
  },
  {
    title: "Home Furniture",
    description: "The home furniture is a necessity in people’s daily life. Whether you’re selling high-end or low-cost, classic or contemporary, wood or synthetics, we offer a wide selection at competitive prices.",
    image: '/Assets/products/furniture2.jpg',
  },
  {
    title: "Office Furniture",
    description: "If you are in the office furniture market, importing from China will be wise. We offer a great diversity of office desks, storage, and tables in creative design.",
    image: '/Assets/products/furniture3.jpg',
  },
  {
    title: "Kid's Furniture",
    description: "We offer children’s furniture suites, school desks, etc. We will provide you with relative certificates of kid’s furniture, such as CPSIA in the USA, CE in the European market, etc.",
    image: '/Assets/products/furniture4.jpg',
  },
  {
    title: "Bathroom Furniture",
    description: "Well-designed bathroom furniture is aesthetical and functional. We have a wide selection of bathroom shelves, shower chairs, and cabinets, from traditional to modern styles for customers.",
    image: '/Assets/products/furniture5.jpg',
  },
  {
    title: "Bedroom Furniture",
    description: "Bedroom furniture materials are increasingly rich. Wood, glass, metal, bamboo, and rattan furniture produced in China all have large export to the US, and European countries.",
    image: '/Assets/products/furniture6.jpg',
  },
];

const Furniture = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Furniture Collection" 
      heroTitle="Explore Our Furniture Collection" 
      heroSubtitle="High-quality furniture for every need"
      heroImage="/Assets/productbanners/furniturebanner.jpg"
    />
  );
};

export default Furniture;
