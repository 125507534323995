import React from "react";
import AdvancedForm from "../components/qoute/qoutecom";
import Qoutebox from "../components/qoute/qoutetwobox";
import Qoutehero from "../components/qoute/qoutehero";
function Qoutepage(){
    return(
        <>
        <Qoutehero />
        <Qoutebox />
        <AdvancedForm />
        </>
    )
}

export default Qoutepage;