// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Baby Feeding",
    description: "Baby feeding utensils, pacifiers, and bottles popularly come in food-grade materials like silicone, plastic, stainless steel, etc.",
    image: '/Assets/products/babysupplies1.jpg',
  },
  {
    title: "Travel Gear",
    description: "Comfort and safety are important to design strollers for your brand. We also offer other custom gears like strap, umbrella, hanger, bags.",
    image: '/Assets/products/babysupplies2.jpg',
  },
  {
    title: "Bibs & Diapering",
    description: "Baby care items are used to keep the baby stay clean. Usually, these items sell in sets as one is not enough for a baby. Using diverse colors or patterns helps make your products stand out.",
    image: '/Assets/products/babysupplies3.jpg',
  },
  {
    title: "Baby Safety",
    description: "Kids grow really fast so new safety problems can arise constantly. Corner guards, mosquito repellent things, anti-lost rope, and more are all beneficial items for selling.",
    image: '/Assets/products/babysupplies4.jpg',
  },
  {
    title: "Baby Toys",
    description: "Things infants can reach for, hold, suck on, shake, and make noise with are good toys for a baby. It’s vital to focus on the toy design and regulations during selling.",
    image: '/Assets/products/babysupplies5.jpg',
  },
  {
    title: "Baby Accessories",
    description: "Except for basic accessories like bibs and bottles, parents also love purchasing customized items like tooth boxes, footprint pads, and milestone blankets for memories.",
    image: '/Assets/products/babysupplies6.jpg',
  },
];

const Babysupplies = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Mother and Kids" 
      heroTitle="Mother & kids" 
      heroSubtitle="High-quality clothing for every need"
      heroImage="/Assets/productbanners/mother-kid-supplies-banner.jpg"
    />
  );
};

export default Babysupplies;
