// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Gardening Tools",
    description: "Essential tools include shovels, saws, buckets, blades, yard carts, etc. And nowadays, electrical equipment would be more efficient for gardeners.",
    image: '/Assets/products/garden1.jpg',
  },
  {
    title: "Fake Plants & Flowers",
    description: "We’re in Yiwu, one of the artificial plants & flower clusters in China. The main materials are silk, velvet fabric, foam, plastic, etc.",
    image: '/Assets/products/garden2.jpg',
  },
  {
    title: "Plant Pots",
    description: "We help you customize plant and flowerpots in different materials like ceramic, metal, wooden, plastic, cloth, and paper.",
    image: '/Assets/products/garden3.jpg',
  },
  {
    title: "Growing Supplies",
    description: "Growing supplies help indoor plants grow faster. They are designed with controllable lights so the plants can always be in the best environment.",
    image: '/Assets/products/garden4.jpg',
  },
  {
    title: "Garden Wear",
    description: "Common clothing and footwear in the garden include cut-resistant gloves, boots, hooded shirts, etc. These products are made of waterproof, durable materials.",
    image: '/Assets/products/garden5.jpg',
  },
  {
    title: "Garden Accessories",
    description: "Garden accessories are items to decorate, organize and protect your garden. We offer products like simulated birds, anti-insect nets, wooden pot shelves, etc.",
    image: '/Assets/products/garden6.jpg',
  },
];

const Gardentool = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Garden & Flowers" 
      heroTitle="Explore Our Garden & Flowers Collection" 
      heroImage="/Assets/productbanners/garden-banner.jpg"
    />
  );
};

export default Gardentool;
