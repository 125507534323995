import React, { useState } from 'react';
import './tabsmenu.css';

const Tabmenu = () => {
  const [activeTab, setActiveTab] = useState('Tab 1');

  const handleTabChange = (tab, event) => {
    event.preventDefault();
    setActiveTab(tab);
  };

  return (
    <>
    <div className='tab-sec'>
    <div className='container'>
      <div className='title-wrapper slide0'>
        <h2 className='section-title-2'>What We do</h2>
        <div className='separator'></div>
      </div>
      
      <div id='about-us' className='slide0 w-tabs'>
        <div className='tabs-menu w-tab-menu' role='tablist'>
          <a
            onClick={(e) => handleTabChange('Tab 1', e)}
            className={`tabm w-inline-block w-tab-link ${activeTab === 'Tab 1' ? 'w--current' : ''}`}
            href='#w-tabs-0-data-w-pane-0'
            role='tab'
            aria-controls='w-tabs-0-data-w-pane-0'
            aria-selected={activeTab === 'Tab 1'}
            tabIndex='-1'
          >
            <div>management</div>
          </a>
          <a
            onClick={(e) => handleTabChange('Tab 2', e)}
            className={`tabm w-inline-block w-tab-link ${activeTab === 'Tab 2' ? 'w--current' : ''}`}
            href='#w-tabs-0-data-w-pane-1'
            role='tab'
            aria-controls='w-tabs-0-data-w-pane-1'
            aria-selected={activeTab === 'Tab 2'}
            tabIndex='-1'
          >
            <div>development</div>
          </a>
          <a
            onClick={(e) => handleTabChange('Tab 3', e)}
            className={`tabm w-inline-block w-tab-link ${activeTab === 'Tab 3' ? 'w--current' : ''}`}
            href='#w-tabs-0-data-w-pane-2'
            role='tab'
            aria-controls='w-tabs-0-data-w-pane-2'
            aria-selected={activeTab === 'Tab 3'}
            tabIndex='-1'
          >
            <div>sourcing</div>
          </a>
          <a
            onClick={(e) => handleTabChange('Tab 4', e)}
            className={`tabm w-inline-block w-tab-link ${activeTab === 'Tab 4' ? 'w--current' : ''}`}
            href='#w-tabs-0-data-w-pane-3'
            role='tab'
            aria-controls='w-tabs-0-data-w-pane-3'
            aria-selected={activeTab === 'Tab 4'}
          >
            <div>agency</div>
          </a>
        </div>

        <div className='tabs-content w-tab-content'>
          <div
            data-w-tab='Tab 1'
            className={`w-tab-pane ${activeTab === 'Tab 1' ? 'w--tab-active' : ''}`}
            id='w-tabs-0-data-w-pane-0'
            role='tabpanel'
            aria-labelledby='w-tabs-0-data-w-tab-0'
          >
            <div className='tab-content'>
              <p>
              Our dedicated team manages every step of your product's journey. From finding the right suppliers to overseeing the delivery, we handle it all. You can count on us to keep everything on track, so you don’t have to worry about 
              </p>
              {/* <p>
                Be it the right branded product, your own label, or complete advice on your range in the areas
                of indoor/outdoor sports, camping, trekking – we can help you to find the right product quality
                from the right producer, to save costs, and to give you the whole process from the product
                development to the shipment and delivery.
              </p> */}
              <div className='w-layout-grid grid'>
                <a className='img-grid grid-item1 w-inline-block w-lightbox'>
                  <img src='/Assets/images/codes.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid grid-item2 large w-inline-block w-lightbox'>
                  <img src='/Assets/images/color.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid grid-item3 w-inline-block w-lightbox'>
                  <img src='/Assets/images/Featured4.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid grid-item4 w-inline-block w-lightbox'>
                  <img src='/Assets/images/featured1.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid grid-item5 w-inline-block w-lightbox'>
                  <img src='/Assets/images/manu.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid grid-item6 w-inline-block w-lightbox'>
                  <img src='/Assets/images/scaled.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
              </div>
            </div>
          </div>

          <div
            data-w-tab='Tab 2'
            className={`w-tab-pane ${activeTab === 'Tab 2' ? 'w--tab-active' : ''}`}
            id='w-tabs-0-data-w-pane-1'
            role='tabpanel'
            aria-labelledby='w-tabs-0-data-w-tab-1'
          >
            <div className='tab-content'>
              <p>
              Got a product idea? We’ll bring it to life. Our development services include everything from initial design and prototyping to production planning. We work closely with you to ensure your product matches your vision perfectly.
              </p>
              {/* <p>
                In the end, a good product fulfills the original idea, satisfies the customer’s needs, and of
                course, in the end, also satisfies the seller.
              </p>
              <p>
                We organize this development process and work with our partners to ensure timely procurement.
                Thanks to many years of experience and a good network, we are able to manage the entire process.
                Starting with the first ideas through the sampling and up to the definitive delivery as requested.
              </p> */}
              <div className='w-layout-grid grid'>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/bags.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid large w-inline-block w-lightbox'>
                  <img src='/Assets/images/slippers.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/table.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/ipad.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/alpha.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/solar.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
              </div>
            </div>
          </div>

          <div
            data-w-tab='Tab 3'
            className={`w-tab-pane ${activeTab === 'Tab 3' ? 'w--tab-active' : ''}`}
            id='w-tabs-0-data-w-pane-2'
            role='tabpanel'
            aria-labelledby='w-tabs-0-data-w-tab-2'
          >
            <div className='tab-content'>
              <p>
              Finding a reliable supplier in China can be tricky, but that’s where we come in. We help you find the best suppliers, get accurate quotations, and negotiate the best deals. Our aim is to connect you with partners who offer great quality and value.
              </p>
              {/* <p>
                Sourcing for the right manufacturer, the right product, or the right brand is the main part of our
                service. It is worthwhile to carefully examine the possibilities and to find the right partner for
                your own project. With our office in Shenzhen, we are always with the dedicated person in the
                productions and can therefore guarantee that everything runs smoothly.
              </p>
              <p>
                This closeness to the producers is an essential part of quality assurance, sampling, or being able
                to answer questions about production quickly and specifically.
              </p>
              <p>
                Our product sourcing expertise is in the field of indoor/outdoor sports, camping and hiking, water
                sports, snow sports, fitness items, general electronics, machinery and hardware tools, electric
                bike, and electric vehicle industry.
              </p> */}
              <div className='w-layout-grid grid'>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/1.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid large w-inline-block w-lightbox'>
                  <img src='/Assets/images/2.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/3.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/4.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/5.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/6.jpeg' loading='lazy' alt='' className='img-lb' />
                </a>
              </div>
            </div>
          </div>

          <div
            data-w-tab='Tab 4'
            className={`w-tab-pane ${activeTab === 'Tab 4' ? 'w--tab-active' : ''}`}
            id='w-tabs-0-data-w-pane-3'
            role='tabpanel'
            aria-labelledby='w-tabs-0-data-w-tab-3'
          >
            <div className='tab-content'>
              <p>
              Think of us as your all-in-one manufacturing partner. We handle everything from product design and supplier selection to quality checks and shipping. Our full-service approach means you can focus on running your business while we manage the rest.</p>
              {/* <p>
                We also have very good contacts with the leading producers of outdoor sports, camping, and water
                sports products, machinery, tools, and general trading together with the brands that we represent
                in multiple territories partners and resellers, we are able to offer you a branded product as well
                as a house label OEM/ODM complete solution.
              </p> */}
              <div className='w-layout-grid grid'>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/Automated.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid large w-inline-block w-lightbox'>
                  <img src='/Assets/images/inspections.png' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/wWH.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/Warehousing.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/air.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
                <a className='img-grid w-inline-block w-lightbox'>
                  <img src='/Assets/images/sea.jpg' loading='lazy' alt='' className='img-lb' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default Tabmenu;
