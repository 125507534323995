// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';

const products = [
  {
    title: "Fitness Equipment",
    description: "There are numerous items available for fitness enthusiasts. We offer fitness equipment for gyms or homes. Home exercise gear is hot, especially in the US market.",
    image: '/Assets/products/sport1.jpg',
  },
  {
    title: "Clothing",
    description: "We provide all gym clothing like yoga suits, t-shirts, bike shorts, etc. If you have your own design, we also help make it a reality every step of the way.",
    image: '/Assets/products/sport2.jpg',
  },
  {
    title: "Yoga",
    description: "Our yoga products include mats, yoga wears, exercise balls, and other accessories. We help you create private-label yoga products by printing a logo, customizing packaging, and more.",
    image: '/Assets/products/sport3.jpg',
  },
  {
    title: "Sports & Games",
    description: "There are sporting goods and outdoor games fun for both kids and adults, such as balls, climbing walls, kid’s scooters, etc. People can enjoy themselves, and at the same time exercise.",
    image: '/Assets/products/sport4.jpg',
  },
  {
    title: "Footwear",
    description: "Athletic footwear is designed for exercising, sports, and some recreational activity. Different from general wear, they’re usually more sweat-wicking, breathable, and more durable.",
    image: '/Assets/products/sport5.jpg',
  },
  {
    title: "Accessories",
    description: "There are also some exercise accessories helping to protect people from hurt during exercise such as muscle tape, waist belts, massage guns, etc. It’s a huge niche market for e-commerce businesses.",
    image: '/Assets/products/sport6.jpg',
  },
];

const Sport = () => {
  return (
    <ProductGridLayout 
      products={products} 
      title="Sport Collection" 
      heroTitle="Explore Our Sport & Entertainment Collection" 
      heroSubtitle="High-quality Sport collection for every need"
      heroImage="/Assets/productbanners/sportbanner.jpg"
    />
  );
};

export default Sport;
