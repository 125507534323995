// src/pages/Disclaimer.js
import React from 'react';
import '../components/privacyPolicy.css';

const Disclaimer = () => {
  return (
    <div className='policy-sec'>
      <div className="privacy-policy-page">
        <h1>Disclaimer</h1>
        <p>
          The information provided by Soursee (“we,” “us,” or “our”) on our website and through our services is for general informational purposes only. While we strive to keep the information up-to-date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
        </p>

        <section>
          <h2>1. Professional Advice Disclaimer</h2>
          <p>
            The information provided on this site does not constitute professional advice and should not be relied upon as such. It is your responsibility to consult a professional in the relevant field before making any decisions based on the content of this website. Soursee is not responsible for any actions taken as a result of using the information provided on this site.
          </p>
        </section>

        <section>
          <h2>2. External Links Disclaimer</h2>
          <p>
            Our website may contain links to third-party websites or services that are not owned or controlled by Soursee. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
          </p>
          <p>
            Soursee shall not be liable for any damage or loss caused, or alleged to be caused, by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.
          </p>
        </section>

        <section>
          <h2>3. Endorsement Disclaimer</h2>
          <p>
            Any reference to a specific product, service, process, or manufacturer does not constitute or imply an endorsement or recommendation by Soursee. The views and opinions expressed in any articles, reviews, or other content on the site are those of the authors and do not necessarily reflect the official policy or position of Soursee.
          </p>
        </section>

        <section>
          <h2>4. Testimonials Disclaimer</h2>
          <p>
            The site may contain testimonials by users of our services. These testimonials reflect real-life experiences and opinions of such users. However, the experiences are personal to those particular users and may not necessarily be representative of all users of our services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.
          </p>
          <p>
            The testimonials on the site are submitted in various forms such as text, audio, and/or video, and are reviewed by us before being posted. They appear on the site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for brevity, where the full testimonial contained extraneous information not relevant to the general public.
          </p>
        </section>

        <section>
          <h2>5. Limitation of Liability</h2>
          <p>
            In no event shall Soursee, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the service; (ii) any conduct or content of any third party on the service; (iii) any content obtained from the service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
          </p>
        </section>

        <section>
          <h2>6. Changes to This Disclaimer</h2>
          <p>
            We may update this Disclaimer from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Disclaimer periodically to stay informed about how we are protecting your information and maintaining our legal obligations.
          </p>
        </section>

        <section>
          <h2>7. Contact Us</h2>
          <p>
            If you have any questions about this Disclaimer, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> info@soursee.com<br />
            <strong>Phone:</strong> +86 188 1857 8873<br />
            <strong>Address:</strong> A201, Yuxing Building, Shajing Road, Bao'an District, Shenzhen - CHINA (518104)
          </p>
        </section>
      </div>
    </div>
  );
};

export default Disclaimer;
