import React from "react";
import Servicesection from "../components/servicecom/servicesection1";
import Servicesbox from "../components/servicecom/servicebox";
import Servicecolumn from "../components/servicecom/servicecolumn1";
import Howtouse from "../components/servicecom/htubox";
import Qoutecta from "../components/qoute/qoutecta";
function Servicepage(){
    return(
       <><Servicesection />
       <Servicesbox />
       <Servicecolumn />
       <Howtouse />
       <Qoutecta />
       </>
    )
}


export default Servicepage;