import React from 'react';
import './App.css';
import './components/ipad/ipad.css';
import './components/Header and global css/global.css';
import Header from './components/Header and global css/header';
import Homepage from './pages/home';
import Servicepage from './pages/Servicepage';
import Qoutepage from './pages/qoutepage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Ourproducts from './pages/ourproducts';
import ApparelGrid from './components/product/productgrid';
import Furniture from './components/product/furniture';
import Bags from './components/product/bags';
import Beauty from './components/product/beauty';
import Toy from './components/product/toy';
import Sport from './components/product/sport';
import Homesupplies from './components/product/homesupplies';
import Gardentool from './components/product/gardentool';
import Electronics from './components/product/electronics';
import Pet from './components/product/pet';
import Hardware from './components/product/hardware';
import Officesupplies from './components/product/office-supplies';
import Babysupplies from './components/product/babysupplies';
import Automotive from './components/product/automotive';
import Adultproducts from './components/product/adultproducts';
import Industrial from './components/product/industrial';
import Package from './components/product/packaging';
import Outdoorsupplies from './components/product/outdoor-supplies';
import Jewelrysupplies from './components/product/jewelry';
import Lighting from './components/product/lighting';
import Footer from './components/Header and global css/footer';
import PrivacyPolicy from './pages/privacypolicy';
import Disclaimer from './pages/disclaimer';

const router = createBrowserRouter([
  { 
    path: "/", 
    element: <><Header /><Homepage /><Footer /></> 
  },
  { 
    path: "/services", 
    element: <><Header /><Servicepage /><Footer /></> 
  },
  { 
    path: "/contact", 
    element: <><Header /><Qoutepage /><Footer /></> 
  },
  { 
    path: "/our-products", 
    element: <><Header /><Ourproducts /><Footer /></> 
  },
  { 
    path: "/our_products/apparel", 
    element: <><Header /><ApparelGrid /><Footer /></> 
  },
  { 
    path: "/our_products/furniture", 
    element: <><Header /><Furniture /><Footer /></> 
  },
  { 
    path: "/our_products/bags-cases", 
    element: <><Header /><Bags /><Footer /></> 
  },
  { 
    path: "/our_products/beauty", 
    element: <><Header /><Beauty /><Footer /></> 
  },
  { 
    path: "/our_products/toys", 
    element: <><Header /><Toy /><Footer /></> 
  },
  { 
    path: "/our_products/sports", 
    element: <><Header /><Sport /><Footer /></> 
  },
  { 
    path: "/our_products/home-supplies", 
    element: <><Header /><Homesupplies /><Footer /></> 
  },
  { 
    path: "/our_products/garden-tools", 
    element: <><Header /><Gardentool /><Footer /></> 
  },
  { 
    path: "/our_products/electronics", 
    element: <><Header /><Electronics /><Footer /></> 
  },
  { 
    path: "/our_products/pet-supplies", 
    element: <><Header /><Pet /><Footer /></> 
  },
  { 
    path: "/our_products/baby-supplies-sourcing", 
    element: <><Header /><Babysupplies /><Footer /></> 
  },
  { 
    path: "/our_products/tools", 
    element: <><Header /><Hardware /><Footer /></> 
  },
  { 
    path: "/our_products/office-supplies", 
    element: <><Header /><Officesupplies /><Footer /></> 
  },
  { 
    path: "/our_products/automotive", 
    element: <><Header /><Automotive /><Footer /></> 
  },
  { 
    path: "/our_products/adult-products", 
    element: <><Header /><Adultproducts /><Footer /></> 
  },
  { 
    path: "/our_products/industrial", 
    element: <><Header /><Industrial /><Footer /></> 
  },
  { 
    path: "/our_products/packaging", 
    element: <><Header /><Package /><Footer /></> 
  },
  { 
    path: "/our_products/outdoor-camping", 
    element: <><Header /><Outdoorsupplies /><Footer /></>
  },
  { 
    path: "/our_products/jewelry", 
    element: <><Header /><Jewelrysupplies /><Footer /></> 
  },
  { 
    path: "/our_products/lighting-sourcing", 
    element: <><Header /><Lighting /><Footer /></> 
  },
  { 
    path: "/privacy-policy", 
    element: <><Header /><PrivacyPolicy /><Footer /></> 
  },
  { 
    path: "/disclaimer", 
    element: <><Header /><Disclaimer /><Footer /></> 
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
